import React from "react";
import "../Styling/css/side.css"
import { Link } from "react-router-dom";
let Sidebar = () => {
    return (
        <div id="sidebar_container">

            <div className="side_item">
                <Link to='/Dashboard' className="Link_con">
                    <i className="bi bi-house-door-fill"></i>
                    <p>Home</p>
                </Link>
            </div>
            <div className="side_item">
                <Link to='Bookings' className="Link_con">
                    <i className="bi bi-calendar-check-fill"></i>
                    <p>Bookings</p>
                </Link>
            </div>
            <div className="side_item">
                <Link to='Artisan' className="Link_con">
                    <i className="bi bi-hammer"></i>
                    <p>Artisans</p>
                </Link>
            </div>
            <div className="side_item">
                <Link to='Update' className="Link_con">
                    <i className="bi bi-cloud-arrow-up-fill"></i>
                    <p>Updates</p>
                </Link>
            </div>
            <div className="side_item">
                <Link to='Popup' className="Link_con">
                    <i className="bi bi-cloud-arrow-up-fill"></i>
                    <p>Popup</p>
                </Link>
            </div>
            <div className="side_item">
                <Link to='Support' className="Link_con">
                    <i className="bi bi-cloud-arrow-up-fill"></i>
                    <p>Support</p>
                </Link>
            </div>
            <div className="side_item">
                <Link to='AllUsers' className="Link_con">
                    <i className="bi bi-people-fill"></i>
                    <p>All Users</p>
                </Link>
            </div>
            <div className="side_item">
                <Link to='Admin' className="Link_con">
                    <i className="bi bi-people-fill"></i>
                    <p>Admins</p>
                </Link>
            </div>
            <div className="side_item">
                <Link to='Account' className="Link_con">
                    <i className="bi bi-person-circle"></i>
                    <p>Account</p>
                </Link>
            </div>
        </div>
    )
}
export default Sidebar
import React, { useState } from "react";
import '../../../Styling/css/artisan.css'
import '../../../Styling/css/dash.css'
import List from "./Lists/lists";
import { collection, query, getDocs, getFirestore, doc, where, updateDoc } from "firebase/firestore";
import { app, app2 } from "../../../firebaseConfig";
import { useEffect } from "react";
import Subscribers from "../Users/subscribers";
const db = getFirestore(app2)
const db1 = getFirestore(app)
let Artisan = () => {
    const [artisans, setArtisans] = useState(false)
    const [orders, setOrders] = useState([])
    const [current, setCurrent] = useState(true)
    const [all, setAll] = useState(true)
    let fetchdata = async (value) => {
        if (value) {
            setCurrent(true)
            let tmp = []
            const docRef = collection(db, "artisans");
            let data = await getDocs(docRef)
            data.forEach(async (doc) => {
                const data = doc
                tmp.push(data)
            })
            setOrders(tmp)
        }
        else {
            setCurrent(true)
            let tmp = []
            const docRef = collection(db, "artisans");
            const q = query(docRef, where("approved", "==", false))
            let data = await getDocs(q)
            data.forEach(async (doc) => {
                const data = doc
                tmp.push(data)
            })
            setOrders(tmp)
        }
    }
    let fetchActiveJobs = async () => {
        setCurrent(false)
        let tmp = []
        const docRef = collection(db, "artisans");
        const q = query(docRef, where("active", "==", true))
        let data = await getDocs(q)
        data.forEach(async (doc) => {
            const data = doc
            tmp.push(data)
        })
        setOrders(tmp)
    }
    const approve = async (id) => {
        const ref = doc(db, "artisans", id)
        updateDoc(ref, {
            approved: true
        }).then(() => {
            fetchdata(true)
            setAll(true)
        })
    }
    const markComplete = async (id, oID) => {
        const ref = doc(db, "artisans", id)
        updateDoc(ref, {
            active: false,
        }).then(() => {
            const ref2 = doc(db1, "orders", oID)
            updateDoc(ref2, {
                State: "fulfilled"
            }).then(el => {
                console.log("completed", el)
            })
            fetchActiveJobs()
        })
    }
    useEffect(() => {
        fetchdata(true)
    }, [])
    return (
        <div className="middle_container artisans">
            <div className="container-buttons">
                <button
                    className="btn btn-primary"
                    style={{ backgroundColor: "var(--main-color)" }} onClick={() => {
                        setAll(false)
                        fetchdata(false)
                        setArtisans(false)
                    }}
                >
                    New Artisans
                </button>
                <button
                    className="btn btn-primary"
                    style={artisans ? { backgroundColor: "white", color: "var(--main-color)" } : { backgroundColor: "var(--main-color)" }}
                    onClick={() => {
                        fetchActiveJobs()
                        setArtisans(false)
                    }}
                >
                    Active Jobs
                </button>
                <button
                    className="btn btn-primary"
                    style={all ? { backgroundColor: "white", color: "var(--main-color)" } : { backgroundColor: "var(--main-color)" }}
                    onClick={() => {
                        fetchdata(true)
                        setAll(true)
                        setArtisans(false)
                    }}
                >
                    All Artisans
                </button>
                <button
                    className="btn btn-primary"
                    style={(artisans ? { backgroundColor: "white", color: "var(--main-color)" } : { backgroundColor: "var(--main-color)", color: "white" })}
                    onClick={() => {
                        fetchdata(true)
                        setAll(true)
                        setArtisans(true)
                    }}
                >
                    Subscribed Artisans
                </button>
            </div>
            {artisans ? <Subscribers /> : (current ?
                all ? <List data={orders} all={all} /> : <List data={orders} approve={approve} />
                : <List data={orders} complete={markComplete} artisans={true} />)}
        </div>
    )
}
export default Artisan
import React, { useState, useEffect } from "react";
import { getFirestore, doc, getDoc, updateDoc } from "firebase/firestore";
import { app } from "../../../../firebaseConfig";
import '../../../../Styling/css/update.css';
const db = getFirestore(app)
const Popup = () => {
    const [image, setImage] = useState("")
    const [imageFile, setImageFile] = useState(null)
    useEffect(() => {
        const ref = doc(db, "popup", "1")
        getDoc(ref).then(el => {
            const data = el.data()
            if (data.active) {
                setImage(data?.modalUrl)
            }
        })
    }, [])
    const UploadImage = async (e) => {
        e.preventDefault()
        try {
            const image = new FormData()
            image.append('upload_preset', 'qlb8aqjh');
            image.append('file', imageFile);
            console.log(image)
            const response = await fetch("https://api.cloudinary.com/v1_1/du9aa3izh/image/upload", {
                method: "POST",
                body: image
            })
            const data = await response.json()
            const ref = doc(db, "popup", "1")
            updateDoc(ref, {
                modalUrl: data.url,
                active: true
            }).then(val => {
                alert("Image Uploaded")
            })
        } catch (e) {
            console.log(e)
        }
    }
    const setInactive = () => {
        const ref = doc(db, "popup", "1")
        updateDoc(ref, {
            active: false
        }).then(data => {
            alert("popup inactive")
        })
    }
    return (
        <div className="middle_container">
            {image ? <p className="text" style={{
                color: "red"
            }}>Active</p> : <></>}
            <p className="header">
                Edit Popup
            </p>
            <section>
                <form onSubmit={UploadImage}>
                    <input className="form-control" type="file" onChange={e => setImageFile(e.target.files[0])} />
                    <button className="btn btn-primary s_button">Upload</button>
                </form>
                <button className="btn btn-primary m-3" onClick={setInactive}>Set Popup inactive</button>
            </section>
        </div >
    )
}

export default Popup
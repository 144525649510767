import React from "react";

let Pagination = (props) => {
    let nPages = props.nPages;
    let setCurrentPage = props.setCurrentPage;
    let currentPage = props.currentPage;

    const nextPage = () => {
        if (currentPage !== nPages) setCurrentPage(currentPage + 1);
    };

    const prevPage = () => {
        if (currentPage !== 1) setCurrentPage(currentPage - 1);
    };

    const goToFirstPage = () => {
        setCurrentPage(1);
    };

    const goToLastPage = () => {
        setCurrentPage(nPages);
    };

    const calculatePageNumbers = () => {
        const totalPageNumbers = Math.min(nPages, 5); // Maximum of five page numbers
        const startPage = Math.max(1, currentPage - Math.floor(totalPageNumbers / 2));
        const endPage = Math.min(nPages, startPage + totalPageNumbers - 1);
        return Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index);
    };

    const pageNumbers = calculatePageNumbers();

    return (
        <div>
            <nav>
                <ul className="pagination justify-content-center">
                    <li className="page-item">
                        <button className="page-link" onClick={goToFirstPage}>
                            First
                        </button>
                    </li>
                    <li className="page-item">
                        <button className="page-link" onClick={prevPage}>
                            Previous
                        </button>
                    </li>
                    {pageNumbers.map((pgNumber) => (
                        <li key={pgNumber} className={`page-item ${currentPage === pgNumber ? 'active' : ''}`}>
                            <button onClick={() => setCurrentPage(pgNumber)} className="page-link">
                                {pgNumber}
                            </button>
                        </li>
                    ))}
                    <li className="page-item">
                        <button className="page-link" onClick={nextPage}>
                            Next
                        </button>
                    </li>
                    <li className="page-item">
                        <button className="page-link" onClick={goToLastPage}>
                            Last
                        </button>
                    </li>
                </ul>
            </nav>
        </div>
    );
};

export default Pagination;

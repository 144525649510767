import { useEffect, useState } from "react";
import { getFirestore, getDocs, collection } from "firebase/firestore";
import { app2 } from "../../../firebaseConfig";
import Pagination from "../Bookings/pagination";
const db = getFirestore(app2)
const Subscribers = () => {
    const [elements, setElements] = useState([])
    const [recordsPerPage] = useState(5);
    const [currentPage, setCurrentPage] = useState(1);
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = elements.slice(indexOfFirstRecord, indexOfLastRecord);
    const nPages = Math.ceil(elements.length / recordsPerPage)
    useEffect(() => {
        getDocs(collection(db, "subscriptions")).then(data => {
            let tmp = []
            data.forEach(data => tmp.push(data.data()))
            console.log(tmp)
            setElements(tmp)
        })
    }, [])
    return (
        <div className="Pagination">
            <div className="Headers i_row">
                <p>Name</p>
                <p>Email</p>
                <p>Phone</p>
                <p>Subscription Start</p>
                <p>Subscription End</p>
            </div>
            {currentRecords.map((el, index) => (
                <div className="i_row" key={index}>
                    <p>{el.Fullname}</p>
                    <p>{el.email}</p>
                    <p>{el.Phone}</p>
                    <p>{el.startDate}</p>
                    <p>{el.endDate}</p>
                </div>
            ))}

            <Pagination
                nPages={nPages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
            />
        </div>
    )
}

export default Subscribers